<template>
  <div class="nk-block">
        <div class="nk-block-head">
            <div class="nk-block-head-content">
                <h3 class="nk-block-title page-title">SoftSuite Admin Dashboard</h3>
                <div class="nk-block-des">
                </div>
                <div class="toggle-wrap nk-block-tools-toggle">
                    <div class="toggle-expand-content" data-content="pageMenu">
                        <ul class="nk-block-tools g-3">
                          <li><router-link :to="{name: 'devteamdates'}" class="btn btn-dark">Dates</router-link></li>
                          <li><router-link :to="{name: 'devteamicons'}" class="btn btn-dark">Icons</router-link></li>
                          <li><router-link :to="{name: 'tenants'}" class="btn btn-dark">Tenants</router-link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div><!-- .nk-block-head -->
        <div class="card card-bordered">
            <div class="card-inner-group">
                <div class="col-12">
                  <div class="card-bordered">
                    <div class="card-inner">
                      <div class="row">
                        <div class="nk-sale-data col-2 text-center">
                          <span style="fontSize: 18px;">Total Tenants Created</span>
                          <span class="amount">{{ adminStats.tenantsCount }}</span>
                          <!-- <span class="sub-title"><span class="change up text-success"><em class="icon ni ni-arrow-long-up"></em>1.93%</span>since last month</span> -->
                        </div>
                        <div class="nk-sale-data col-2 text-center">
                          <span style="fontSize: 18px;">Total Users Created</span>
                          <span class="amount">{{ adminStats.usersCount }}</span>
                          <!-- <span class="sub-title"><span class="change up text-success"><em class="icon ni ni-arrow-long-up"></em>1.93%</span>since last month</span> -->
                        </div>
                        <div class="nk-sale-data col-2 text-center">
                          <span style="fontSize: 18px;">Total Tickets Logged</span>
                          <span class="amount">{{ adminStats.ticketsCount }}</span>
                          <!-- <span class="sub-title"><span class="change up text-success"><em class="icon ni ni-arrow-long-up"></em>1.93%</span>since last month</span> -->
                        </div>
                        <div class="nk-sale-data col-2 text-center">
                          <span style="fontSize: 18px;">Total Holiday Bookings</span>
                          <span class="amount">{{ adminStats.holidayCount }}</span>
                          <!-- <span class="sub-title"><span class="change up text-success"><em class="icon ni ni-arrow-long-up"></em>1.93%</span>since last month</span> -->
                        </div>
                        <div class="nk-sale-data col-2 text-center">
                          <span style="fontSize: 18px;">Total Files Uploaded</span>
                          <span class="amount">{{ adminStats.filesCount }}</span>
                          <!-- <span class="sub-title"><span class="change up text-success"><em class="icon ni ni-arrow-long-up"></em>1.93%</span>since last month</span> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div><!-- .card-inner-group -->
        </div><!-- .card -->
    </div>
</template>

<script>
export default {
  data() {
    return {
      adminStats: [],
    }
  },
  created() {
    this.getView()
    this.getLists()
  },
  methods: {
    getView() {
      this.$http.get('/ViewAccess/account')
      .then(() => {
      })
      .catch(() => {
      })
    },
    getLists() {
      this.$http.get('/admin/Get_Admin_Stats/')
      .then((response) => {
        this.adminStats = response.data
      })
      .catch(() => {
        this.$message.error('There has been an error')
      })
    }
  }
}
</script>

<style>

</style>